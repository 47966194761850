// assets
import { IconSettings } from '@tabler/icons';


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const settings = {
  id: 'settings',
  title: 'Settings',
  type: 'group',
  adminOnly: true,
  children: [
    {
      id: 'shipment-setting',
      title: 'Shipment Setting',
      type: 'collapse',
      icon: IconSettings,

      children: [
        {
          id: 'account-service',
          title: 'Account Service',
          type: 'item',
          url: '/accounts',
          breadcrumbs: false,
        },
        {
          id: 'carrier-account',
          title: 'Carrier Account',
          type: 'item',
          url: '/pages/register/register3',
          breadcrumbs: false,
        },
        {
          id: 'system-service-code',
          title: 'System Service Code',
          type: 'item',
          url: '/pages/login/login3',
          breadcrumbs: false,
        },
        {
          id: 'carrier',
          title: 'Carrier',
          type: 'item',
          url: '/pages/register/register3',
          breadcrumbs: false,
        },
      ],
    },
    {
      id: 'rate-setting',
      title: 'Rate Setting',
      type: 'collapse',
      icon: IconSettings,

      children: [
        {
          id: 'pricing-rule',
          title: 'Pricing Rule',
          type: 'item',
          url: '/price-rule',
          breadcrumbs: false,
        },
        {
          id: 'base-charge',
          title: 'Base Charge',
          type: 'item',
          url: '/pages/register/register3',
          breadcrumbs: false,
        },
        {
          id: 'surchange',
          title: 'Surchange',
          type: 'item',
          url: '/pages/login/login3',
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default settings;
