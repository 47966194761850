import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Header from './views/Header';
import Footer from './views/Footer';
import theme from './theme';
import './App.css';
import List from './views/List';
import Nav from './nav-view/Navigation';
// import Profile from './views/Profile';
import Detail from './views/Detail';
// import Users from './views/Users';
import NotFound from './views/NotFound';
import Upload from './views/Upload';
import { UserProvider } from './UserContext';
// import CreateUser from './views/CreateUser';
import {useSelector} from "react-redux";
import themes from "./nav-view/theme/themes";
import { lazy } from 'react';

// project imports

import Loadable from './nav-view/ui-component/Loadable';
import AddressesList from "./nav-view/AddressesList";
import AddressProfile from "./views/AddressProfile";
import CreateAddress from "./views/CreateAddress";
import CreateAddress2 from "./views/CreateAddress2";
import AccountTable from "./components/AccountTable";
import PriceRuleTable from "./components/PriceRuleTable";
import QuoteView from './components/QuoteView';
import OrderTable from './components/OrderTable';
import {AmplifyAuthenticator, AmplifySignIn} from "@aws-amplify/ui-react";
import {AuthState} from "@aws-amplify/ui-components";
import {useAuth} from "./contexts/AuthContext";
import Login from "./components/Login";

const SamplePage = Loadable(lazy(() => import('./nav-view/view/sample-page')));
const HomePage = Loadable(lazy(() => import('./views/HomePage')));
const Profile =  Loadable(lazy(() => import('./views/Profile')));
const Users =  Loadable(lazy(() => import('./views/Users')));
const CreateUser =  Loadable(lazy(() => import('./views/CreateUser')));

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'rgb(247, 249, 252)',
  },
}));

function Routes() {
  const classes = useStyles();
  const { authState, user } = useAuth();
  const customization = useSelector((state) => state.customization);
  const theme=themes(customization);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route
          render={() => (
            <UserProvider>
              <Paper className={classes.root}>
                {/*<Header />*/}
                <div className={classes.content}>
                  <Switch>
                    {/*<Route exact path="/sample-page" render={()=><Nav child={<SamplePage/>} />} />*/}
                    {/*<Route exact path="/quote" render={()=><Nav child={<QuoteView/>} />} />*/}
                    {/*<Route exact path="/orders" render={()=><Nav child={<OrderTable/>} />} />*/}
                    {/*<Route exact path="/price-rule" render={()=><Nav child={<PriceRuleTable/>} />} />*/}
                    {/*<Route exact path="/accounts" render={()=><Nav child={<AccountTable/>} />} />*/}
                    {/*<Route exact path="/addresses" render={()=><Nav child={<AddressesList/>} />} />*/}
                    {/*<Route exact path="/address/create" render={()=><Nav child={<CreateAddress/>} />} />*/}
                    {/*<Route exact path="/address/create2" render={()=><Nav child={<CreateAddress2/>} />} />*/}
                    {/*<Route exact path="/address-profile/:addressCode/:ownerId" render={()=><Nav child={<AddressProfile/>} />} />*/}
                    <Route exact path="/" render={()=><Nav child={<HomePage/>} />} />
                    <Route
                        exact
                        path="/login"
                        render={() => {
                          // If user is already authenticated, redirect to home
                          if (authState === AuthState.SignedIn) {
                            return <Redirect to="/" />;
                          }
                          // Otherwise show login page
                          return <Login />
                        }}
                    />
                    {/*<Route exact path="/list" render={()=><Nav child={<List/>} />} />*/}
                    {/*<Route exact path="/profile" render={()=><Nav child={<Profile/>} />} />*/}
                    {/*<Route exact path="/users" render={()=><Nav child={<Users/>} />} />*/}
                    {/*<Route exact path="/users/create" render={()=><Nav child={<CreateUser/>} />} />*/}
                    {/*<Route path="/document/:documentId" component={Detail} />*/}
                    {/*<Route exact path="/upload" component={Upload} />*/}
                    <Route path="*" component={NotFound} />
                  </Switch>
                </div>
                {/*<Footer />*/}
              </Paper>
            </UserProvider>
          )}
        />
      </Router>
    </ThemeProvider>
  );
}

export default Routes;
