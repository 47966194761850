import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import iugoMenu from './iugo_menu';
import settings from './settings'
import users from './users'
import jewelBoxMenu from "./miss_jewelbox_menu";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [jewelBoxMenu],
};

export default menuItems;
