import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@material-ui/core/styles';
import {AppBar, Box, Breadcrumbs, CssBaseline, Toolbar, Typography, useMediaQuery} from '@mui/material';
import Header from '../nav-view/MainLayout/NavHeader'
import { SET_MENU } from '../store/actions';
import { drawerWidth } from '../store/constant';
import Sidebar from './MainLayout/SideBar';
import themes from "../nav-view/theme/themes";
import Customization from "./MainLayout/Customization";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import navigation from '../menu-items';
import {Outlet} from "@mui/icons-material";
import MainCard from "./ui-component/cards/MainCard";
import NavigationScroll from "./MainLayout/NavigationScroll";

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

function Nav({child}) {
  // Handle left drawer
    const theme=useTheme();
  // const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  // useEffect(() => {
  //   dispatch({ type: SET_MENU, opened: !matchDownMd });
  //   //Todo react-hooks/exhaustive-deps
  // }, [matchDownMd]);


  // const theme = useTheme();
  // const classes = useStyles(theme);
  return (
      <NavigationScroll>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
          }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
        {/* main content */}
        <Main theme={theme} open={leftDrawerOpened}>
            {/* breadcrumb */}
            {child}
            <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}   navigation={navigation} />
        </Main>
        <Customization />

    </Box>
      </NavigationScroll>
  );
}

export default Nav;
