import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import FileName from './DataGrid/FileName';
import UserView from './UserView';
import { getAllAddresses, deleteAddress } from '../services';
import { getReadableFileSize, getFormattedDate } from '../util';
import LoadingView from './LoadingView';
import List from "../views/List";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function AddressesTable() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(null);

  const fetchData = async () => {
    const data = await getAllAddresses();
    setTableData(data);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    const interval = setInterval(async () => {
      await fetchData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      name: 'AddressId',
      label: 'Address Id',
      options: {
        filter: true,
        filterType: 'textField',
        customFilterListOptions: { render: (v) => `Address Id: ${v}` },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          return (<Link className={classes.root} to={`/address-profile/${val.SK}/${val.UserId}`}>
            <div className={classes.nameLabel}>{val.AddressId}</div>
          </Link>)
              ;
        },
      },
    },
    {
      name: 'Company',
      label: 'Company',
      options: {
        filter: true,
        filterType: 'textField',
      },
    },
    {
      name: 'Attention',
      label: 'Attention',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Country',
      label: 'Country',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Address',
      label: 'Address',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          if (val.Address2 !== '')
          { return <div>{val.Address1}, {val.Address2}, {val.City}, {val.State}, {val.ZipCode}</div>}
          else {  return <div>{val.Address1}, {val.City}, {val.State}, {val.ZipCode}</div>}
        }
      },
    },
    {
      name: 'Phone',
      label: 'Phone',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Type',
      label: 'type',
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => `Type: ${v}` },
        sort: false,
        customBodyRenderLite: function GroupCell(dataIndex) {
          const val = tableData[dataIndex];
          const color = val.Type === "B"?"primary":"secondary"
          return <Chip label={val.Type} color={color}/>;
        },
      },
    },
    {
      name: 'DateUploaded',
      label: 'Uploaded',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          const date = new Date(val.DateUploaded);
          return getFormattedDate(date);
        },
      },
    },
    {
      name: 'UserId',
      label: 'Owner',
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => <UserView userId={v} /> },
        filterOptions:{
          renderValue: val => {
            return  <UserView userId={val} />
          }
        },
        sort: false,
        customBodyRenderLite: function UserViewCell(dataIndex) {
          const val = tableData[dataIndex];
          console.log(val);
          return <UserView userId={val.UserId} />;
        },
      },
    },
  ];


  const options = {
    filterType: 'dropdown',
    selectableRows: 'single',
    fixedSelectColumn: false,
    print: false,
    download: false,
    onRowsDelete: (rowsDeleted) => {
      const itemIdsToDelete = rowsDeleted.data.map((i) => tableData[i.dataIndex]);
      return Promise.all(itemIdsToDelete.map((item) => deleteAddress(item.SK, item.UserId)));
    },
  };

  return (
    <>
      {tableData && (
        <MUIDataTable
          className={classes.root}
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
      {!tableData && <LoadingView />}
    </>
  );
}
