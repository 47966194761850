import React from 'react';
import { useHistory } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {I18n} from "aws-amplify";

I18n.putVocabularies({
    en: {
        'Invalid phone number format': 'Please enter a valid phone number (e.g., +1234567890)',
        'Password requirements': 'Password must contain at least 8 characters, including uppercase, lowercase, numbers, and symbols',
        'Confirmation Code': 'Confirmation code from your email',  // Updated this line
        'Enter your code': 'Enter the confirmation code from your email',  // Added this line for additional context
        'Verification code': 'Verification code from your email'
    }
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        '& .amplify-authenticator': {
            width: '100%',
            maxWidth: '400px',
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '400px',
        marginTop: theme.spacing(2)
    }
}));

const Login = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleCancel = () => {
        history.push('/'); // Always redirect to home page
    };

    return (
        <div className={classes.container}>
            <AmplifyAuthenticator>
                <AmplifySignIn
                    slot="sign-in"
                    usernameAlias="email"
                    headerText="Miss JewelBox"
                    components={{
                        Footer: () => (
                            <div className={classes.footer}>
                                <Button
                                    onClick={handleCancel}
                                    sx={{
                                        color: '#708090',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            color: '#4a5a6a'
                                        },
                                        textTransform: 'none',
                                        fontSize: '1rem'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        )
                    }}
                />
            </AmplifyAuthenticator>
        </div>
    );
};

export default Login;
