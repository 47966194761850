// assets
import { IconCurrencyDollar,
  IconTruckDelivery,
  IconHelp,
  IconForms,
  IconShip,
  IconHome } from '@tabler/icons';

// ============|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=============== //

const iugoMenu = {
  id: 'iugo-menu',
  type: 'group',
  children: [
    {
      id: 'quote',
      title: 'Quote',
      type: 'item',
      url: '/quote',
      icon: IconForms,
      breadcrumbs: false,
    },
    {
      id: 'orders',
      title: 'Orders',
      type: 'collapse',
      icon: IconHelp,
      children: [
        {
          id: 'shipment-orders',
          title: 'Shipment Orders',
          type: 'item',
          url: '/orders',
          icon: IconShip,
          breadcrumbs: false,
        },
      ]
    },
    {
      id: 'my-address',
      title: 'My Address',
      type: 'item',
      url: '/addresses',
      icon: IconHome,
      breadcrumbs: false,
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: '/sample-page',
      icon: IconTruckDelivery,
      breadcrumbs: false,
    },
    {
      id: 'recharge',
      title: 'Recharge',
      type: 'item',
      url: '/sample-page',
      icon: IconCurrencyDollar,
      breadcrumbs: false,
    },
  ],
};

export default iugoMenu;
