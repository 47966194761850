import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import FileName from './DataGrid/FileName';
import UserView from './UserView';
import { getAllPriceRule } from '../services';
import { getReadableFileSize, getFormattedDate } from '../util';
import LoadingView from './LoadingView';
import List from "../views/List";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function PriceRuleTable() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(null);

  const fetchData = async () => {
    const data = await getAllPriceRule();
    setTableData(data);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    const interval = setInterval(async () => {
      await fetchData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      name: 'Name',
      label: 'Name',
      options: {
        filter: true,
        filterType: 'textField',
        customFilterListOptions: { render: (v) => `Account Code: ${v}` },
        sort: true,
        // customBodyRenderLite: (dataIndex) => {
        //   const val = tableData[dataIndex];
        //   return (<Link className={classes.root} to={`/address-profile/${val.SK}/${val.UserId}`}>
        //     <div className={classes.nameLabel}>{val.AddressId}</div>
        //   </Link>)
        //       ;
        // },
      },
    },
    {
      name: 'Type',
      label: 'Type',
      options: {
        filter: true,
        filterType: 'textField',
      },
    },
    {
      name: 'WeightUnit',
      label: 'Weight Unit',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'DimUnit',
      label: 'Dim Unit',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'DimDivisor',
      label: 'Dim Divisor',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Currency',
      label: 'Currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Zone',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'BaseRate',
      label: 'Base Rate',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Surcharge',
      label: 'Surcharge',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'Fuel',
      label: 'Fuel',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          return val.Fuel + "%";
        }
      }
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: function GroupCell(dataIndex) {
          const val = tableData[dataIndex];
          const color = val.Status ?"primary":"secondary"
          const value = val.Status ?"T":"F"
          return <Chip label={value} color={color}/>;
        },
      },
    },
    {
      name: 'Memo',
      label: 'Memo',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'CreatedTime',
      label: 'Created Time',
      options: {
        filter: false,
        sort: true,
        sortOrder: 'asc',
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          return getFormattedDate(new Date(val.CreatedTime));
        },
      },
    },
  ];


  const options = {
    filterType: 'dropdown',
    selectableRows: 'single',
    fixedSelectColumn: false,
    print: false,
    download: false,
    // onRowsDelete: (rowsDeleted) => {
    //   const itemIdsToDelete = rowsDeleted.data.map((i) => tableData[i.dataIndex]);
    //   return Promise.all(itemIdsToDelete.map((item) => deleteAddress(item.SK, item.UserId)));
    // },
  };

  return (
    <>
      {tableData && (
        <MUIDataTable
          className={classes.root}
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
      {!tableData && <LoadingView />}
    </>
  );
}
