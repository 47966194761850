import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { getAllOrders, getQuote } from '../services';
import LoadingView from './LoadingView';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import {Button, CardHeader, TextField, Tab, Tabs, Badge} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {CardContent, FormLabel} from "@mui/material";
import {useUser} from "../UserContext";
import Autocomplete from '@mui/material/Autocomplete';
import {countries} from "../data/countries";
import {states} from "../data/states";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Home from "@material-ui/icons/Home";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {addressVerify} from "../services";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserView from './UserView';
import LineWithTags from './customized/LineWithTags';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem
  } from '@mui/x-data-grid';
  import {randomId,
  } from '@mui/x-data-grid-generator';
  
const useStyles = makeStyles(() => ({
  root: {}
}));

export default function OrderTable() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [myAddresses, setMyAddresses] = useState([]);
  const [packages, setPackages] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const {user, setUser} = useUser();
  const [loadingQuoteData, setLoading] = useState(false);
  
  // ship from address forms
  const [myAddressesFrom, setMyAddressFrom] = useState(null);
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [type, setType] = useState('R');

  const [city, setCity] = useState('');
  const [fromState, setStateValue] = useState(null);
  const [country, setCountry] = useState(countries[0]);
  const [zipCode, setZipCode] = useState('');
  const [phone, setPhone] = useState('');
  const [autocomplete, setAutocomplete] = useState();

  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [successMessage, setSuccessMessage] = useState("The address successfully updated")
  const [errorMessage, setErrorMessage] = useState("Could not update address. Please try again later.")

  const [verifyMessage, setVerifyMessage] = useState(null)


  // ship to address forms
  const [myAddressesTo, setMyAddressTo] = useState(null);
  const [shipToAddress1, setShipToAddress1] = useState('');
  const [shipToAddress2, setShipToAddress2] = useState('');
  const [shipToType, setShipToType] = useState('R');

  const [shipToCity, setShipToCity] = useState('');
  const [shipToState, setShipToStateValue] = useState(null);
  const [shipToCountry, setShipToCountry] = useState(countries[0]);
  const [shipToZipCode, setShipToZipCode] = useState('');
  const [shipToPhone, setShipToPhone] = useState('');
  const [isFormToValid, setFormToIsValid] = useState(false);
  const [submittingTo, setSubmittingTo] = useState(false);

  async function verify(addr, handleSuccess, handleError) {
      try {
          const response = await addressVerify(addr)
          if (response.data.status === "200")
          {
              console.log(response.data)
              handleSuccess(response.data);
              return;
          }
          else if (response.data.status === "400")
          {
              handleError(response.data.message);
              return;
          }

      } catch (err) {
          console.log(err)
          handleError("Error while verifying address. Please try again");
          return;
      }
  };

  const verifyFromAddress = async () => {
      setSubmitting(true);
      const address = {
          Address1: address1,
          State: fromState.abbreviation,
          City: city,
          ZipCode: zipCode,
          Country: country.code,
      }
      verify(address,
        (data) => {
              setVerifyMessage(data.message);
              setAddress1(data.address.Address1);
              setCountry(getCountry(data.address.Country));
              setStateValue(getState(data.address.State));
              setCity(data.address.City);
              setZipCode(data.address.ZipCode);
              setSubmitting(false);
              return;
          },
          (errorMessage) => {
              setSubmitting(false);
              setVerifyMessage(errorMessage);
              return;
          }
      )
  };

  const verifyToAddress = () => {
      setSubmittingTo(true);
      const address = {
          Address1: shipToAddress1,
          State: shipToState.abbreviation,
          City: shipToCity,
          ZipCode: shipToZipCode,
          Country: shipToCountry.code,
      }
      verify(address,
          (data) => {
              setVerifyMessage(data.message);
              setShipToAddress1(data.address.Address1);
              setShipToCountry(getCountry(data.address.Country));
              setShipToStateValue(getState(data.address.State));
              setShipToCity(data.address.City);
              setShipToZipCode(data.address.ZipCode);
              setSubmittingTo(false);
              return;
          },
          (errorMessage) => {
              setSubmittingTo(false);
              setVerifyMessage(errorMessage)
              return;
          }
      )
  };


  const fetchData = async () => {
    const resp = await getAllOrders();
    console.log("orders: " + resp.orders)
    setTableData(resp.orders);
  };
  

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    // address form control
    if ((!address1 || address1.length < 1) ||
        (!type || type.length < 1) ||
        (!city || city.length < 1) ||
        (!fromState || !fromState.abbreviation || fromState.abbreviation.length < 1) ||
        (!country || !country.code || country.code.length < 1) ||
        (!zipCode || zipCode.length < 1))
    {
        setIsValid(false);
    } else {
        setIsValid(true);
    }

    // address to control
    if ((!shipToAddress1 || shipToAddress1.length < 1) ||
        (!shipToType || shipToType.length < 1) ||
        (!shipToCity || shipToCity.length < 1) ||
        (!shipToState || !shipToState.abbreviation || shipToState.length < 1) ||
        (!shipToCountry || !shipToCountry.code || shipToCountry.length < 1) ||
        (!shipToZipCode || shipToZipCode.length < 1))
    {
        setFormToIsValid(false);
    } else {
        setFormToIsValid(true);
    }
  }, [address1, city, fromState, country, zipCode, type, shipToAddress1, shipToCity, shipToState, shipToCountry, shipToZipCode, shipToType]);

  const columns = [
    {
      name: 'orderId',
      label: 'Order Id',
      options: {
        filter: false,
        sort: true,
      }
      /**
       * options: {
        filter: true,
        filterType: 'textField',
        customFilterListOptions: { render: (v) => `Address Id: ${v}` },
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          return 
          //return (<Link className={classes.root} to={`/address-profile/${val.SK}/${val.UserId}`}>
          //  <div className={classes.nameLabel}>{val.AddressId}</div>
          //</Link>)
              ;
        },
      }, **/
    },
    {
      name: 'fullAddressTo',
      label: 'Address',
      options: {
        filter: true,
        filterType: 'textField',
      },
    },
    { 
      name: 'packages',
      label: 'Packages',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
            const val = tableData[dataIndex].packages;
            console.log("package:" + JSON.stringify(val))
            return <List dense>
            {val.map((value, index) => (
              <ListItem key={index}>
                <div className="App">
                  <LineWithTags data={value} />
                </div>
              </ListItem>
            ))}
          </List>
        }
      },
    },
    {
      name: 'trackingNumber',
      label: 'Shipping',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'charge',
      label: 'Estimated',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const val = tableData[dataIndex];
          return "$" + val.charge
        }
      },
    },
    {
      name: 'createAt',
      label: 'Time',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
            const time = new Date(value);
            return time.toLocaleString();
        }
      },
    },
    {
      name: 'userId',
      label: 'User',
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => <UserView userId={v} /> },
        filterOptions:{
          renderValue: val => {
            return  <UserView userId={val} />
          }
        },
        sort: false,
        customBodyRenderLite: function UserViewCell(dataIndex) {
          const val = tableData[dataIndex];
          console.log(val);
          return <UserView userId={val.userId} />;
        },
      },
    },
  ];


  const options = {
    filterType: 'dropdown',
    selectableRows: 'single',
    fixedSelectColumn: false,
    print: false,
    download: false,
    onRowsDelete: (rowsDeleted) => {
      const itemIdsToDelete = rowsDeleted.data.map((i) => tableData[i.dataIndex]);
      return Promise.all(itemIdsToDelete.map((item) => deleteAddress(item.SK, item.UserId)));
    },
  };

  // package table control
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setPackages(packages.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = packages.find((row) => row.id === id);
    if (!editedRow.isNew) {
      setPackages(packages.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setPackages(packages.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  
  function EditToolbar(props) {
    const { setPackages, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setPackages((oldRows) => [...oldRows, { id, packageQty: '1', length:'', width: '', height: '', weight: '', insuredValue: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'length' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add package
        </Button>
      </GridToolbarContainer>
    );
  }
  
  const packageColumns = [
    {
      headerName: "Package Qty",
      field: "packageQty",
      editable: true,
      width: 100,
      type: "number"
    },
    {
      headerName: "Length(IN)",
      field: "length",
      editable: true,
      width: 100,
      type: "number"
    },
    {
      headerName: "Width(IN)",
      field: "width",
      editable: true,
      width: 100,
      type: "number"
    },
    {
      headerName: "Height(IN)",
      field: "height",
      editable: true,
      width: 100,
      type: "number"
    },
    {
      headerName: "Weight(LBS/per)",
      field: "weight",
      editable: true,
      width: 150,
      type: "number"
    },
    {
      headerName: "Declared Value(USD)",
      field: "insuredValue",
      editable: true,
      width: 150,
      type: 'number'
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 150,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        }
    }
]

// address form functions
const getAllQuotes = async () => {
    setLoading(true);
    setTableData(undefined);
    if (packages.length > 0) {
        const packageData = packages.map((p, index) => {
            for (let i = 0; i < p.packageQty; i++) {
                return {
                    "weight": p.weight,
                    "length": p.length,
                    "width": p.width,
                    "height": p.height,
                    "insured_currency": "USD",
                    "insured_value": p.insuredValue
                };
            }
        });
        try {
            const response = await getQuote({
                "packages": packageData,
                "residential": shipToType == "R" ? true : false,
                "shipper": "iugo",
                "ship_addr1": address1,
                "ship_city": city,
                "ship_state": fromState.abbreviation,
                "ship_code": zipCode,
                "ship_country": country.code,
                "to_country": shipToCountry.code,
                "to_state": shipToState.abbreviation,
                "to_code": shipToZipCode
            });
            if (response.status === 200) {
                console.log(response.data);
                setTableData(response.data);
                setLoading(false);
                setSuccessMessage(response.message);
                return;
            } else if (response.status === 400) {
                setLoading(false);
                setIsSuccessVisible(false);
                setIsErrorVisible(true);
                setErrorMessage("Error on quote: " + response.message);
                return;
            }
        } catch (err) {
            console.log(err)
            setLoading(false);
            setIsSuccessVisible(false);
            setIsErrorVisible(true);
            return;
        }
    } else {
        console.log("Missing package info.")
    }
};

const getButtonIcon = () => {
    if (submitting) {
        return <CircularProgress size={22}/>;
    }
    return <Home/>;
};

const getButtonIconTo = () => {
    if (submittingTo) {
        return <CircularProgress size={22}/>;
    }
    return <Home/>;
};

const getCountry = (c) => {
    const item = countries.find((opt) => {
        if (opt.searchKey.includes(c))
            return opt;
    })
    return item || null;
};

const getState = (abbreviation) => {
    return states.find((opt) => { if(opt.abbreviation == abbreviation) return opt }) || null;
};

const selectMyAddress = (addr) => {
    if(addr) {
        setAddress1(addr.Address1);
        setAddress2(addr.Address2);
        setStateValue(getState(addr.State));
        setCountry(getCountry(addr.Country));
        setCity(addr.City);
        setZipCode(addr.ZipCode);
        setType(addr.Type);
        setMyAddressFrom(addr)
    } else {
        setAddress1('');
        setAddress2('');
        setStateValue(null);
        setCountry(countries[0]);
        setCity('');
        setZipCode('');
        setType('R');
        setMyAddressFrom(null);
    }
};

const selectMyAddressTo = (addr) => {
    if(addr) {
        setShipToAddress1(addr.Address1);
        setShipToAddress2(addr.Address2);
        setShipToStateValue(getState(addr.State));
        setShipToCountry(getCountry(addr.Country));
        setShipToCity(addr.City);
        setShipToZipCode(addr.ZipCode);
        setShipToType(addr.Type);
        setMyAddressTo(addr);
    } else {
        setShipToAddress1('');
        setShipToAddress2('');
        setShipToStateValue(null);
        setShipToCountry(countries[0]);
        setShipToCity('');
        setShipToZipCode('');
        setShipToType('R');
        setMyAddressTo(null);
    }
};

const [value, setValue] = React.useState('1');

const handleChange = (event, newValue) => {
  setValue(newValue);
};
const badgeStyle = {
    "& .MuiBadge-badge": {
      right: 'unset',
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
        right: 'unset'    }
  }
  return (
      <Grid container direction="row" spacing={1}>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label={<Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} color="secondary" badgeContent={100}>All</Badge>} value="1"/>
            <Tab label={<Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} color="secondary" badgeContent={1}>Pending</Badge>} value="2" />
            <Tab label={<Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} color="secondary" badgeContent={100}>Shipped</Badge>} value="3" />
            <Tab label={<Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} color="secondary" badgeContent={10}>Canceled</Badge>} value="4" />
            <Tab label={<Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} color="secondary" badgeContent={0}>Error</Badge>} value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">
        <MUIDataTable
          className={classes.root}
          data={tableData}
          columns={columns}
          options={options}
        />
        </TabPanel>
        <TabPanel value="2">Pending</TabPanel>
        <TabPanel value="3">Shipped</TabPanel>
        <TabPanel value="4">Canceled</TabPanel>
        <TabPanel value="5">Error</TabPanel>
      </TabContext>
    </Box>
      </Grid>
  );
}
