import React from 'react';
import Amplify from 'aws-amplify';
import './App.css';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {AmplifyAuthenticator, AmplifySignIn} from '@aws-amplify/ui-react';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import MyRoutes from './Routes';
import {useSelector} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import themes from "./nav-view/theme/themes";
import NavigationScroll from "./nav-view/MainLayout/NavigationScroll";
import {AuthProvider} from "./contexts/AuthContext";

Amplify.configure(window.appConfig);

const helmetContext = {};

function App() {

    const customization = useSelector((state) => state.customization);

    return (
        <AuthProvider>
            <HelmetProvider context={helmetContext}>
                <Helmet titleTemplate="%s | Miss JewelBox" defaultTitle="Miss JewelBox"/>
                <ThemeProvider theme={themes(customization)}>
                    <MyRoutes/>
                </ThemeProvider>
            </HelmetProvider>
        </AuthProvider>
    );
}

export default App;
