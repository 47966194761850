import Page from "../containers/Page";
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {getAddress, updateAddress, addressVerify} from "../services";
import {Button, CardHeader, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CardContent, FormLabel, Grid} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Home from "@material-ui/icons/Home";
import Card from "@material-ui/core/Card";
import LoadingView from "../components/LoadingView";
import MuiAlert from "@material-ui/lab/Alert";
import {useUser} from "../UserContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import {countries} from "../data/countries";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(3),
    },
    card: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        width: '100%',
    },
    container: {
        display: 'flex',
    },
    actionItemsContainer: {
        textAlign: 'right',
        margin: '10px 0',
    },
    alert: {
        marginBottom: theme.spacing(3),
    },
    profilePicPreview: {
        width: '120px',
        height: '120px',
        marginBottom: theme.spacing(4),
    },
    button: {
        marginRight: theme.spacing(1),
    },
}));

const AddressProfile = () => {
    const classes = useStyles();
    // const { ownerId, setOwnerId } =useState('');
    const {addressCode, ownerId} = useParams();
    const [addressData, setAddressData] = useState(null);
    const [address1, setAddress1] = useState('');
    const [addressId, setAddressId] = useState('');
    const [address2, setAddress2] = useState('');
    const [type, setType] = useState('');
    const [company, setCompany] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('US');
    const [zipCode, setZipCode] = useState('');
    const [phone, setPhone] = useState('');
    const [attention, setAttention] = useState('');
    const [updating, setUpdating] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [autocomplete, setAutocomplete] = useState();

    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const [successMessage, setSuccessMessage] = useState("The address successfully updated")
    const [errorMessage, setErrorMessage] = useState("Could not update address. Please try again later.")

    const [verifyMessage, setVerifyMessage] = useState(null)

    const [open, setOpen] = React.useState(false);


    const handleClickOpen = async () => {
        setUpdating(true);
        setOpen(true);
        const address = {
            Address1: address1,
            State: state,
            City: city,
            ZipCode: zipCode,
            Country: country,
        }
        try {
            const response = await addressVerify(address)
            if (response.data.status === "200")
            {
                console.log(response.data)
                setVerifyMessage(response.data.message);
                setAddress1(response.data.address.Address1);
                setCountry(response.data.address.Country);
                setState(response.data.response.State);
                setCity(response.data.response.City);
                setZipCode(response.data.response.ZipCode);
                setUpdating(false);
                return;
            }
            else if (response.data.status === "400")
            {
                setUpdating(false);
                setVerifyMessage(response.data.message)
                return;
            }

        } catch (err) {
            setUpdating(false);
            console.log(err)
            return;
        }

    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        (async () => {
            const data = await getAddress(addressCode, ownerId);
            setAddressData(data);
        })();
    }, [addressCode]);

    useEffect(() => {
        if (!addressData) {
            setDataChanged(false);
            return;
        }
        setCompany(addressData.Company)
        setCity(addressData.City);
        setState(addressData.State);
        setCountry(addressData.Country);
        setZipCode(addressData.ZipCode);
        setPhone(addressData.Phone);
        setAttention(addressData.Attention);
        setAddress1(addressData.Address1);
        setAddress2(addressData.Address2)
        setAddressId(addressData.AddressId);
        setType(addressData.Type)

    }, [addressData]);


    const handlePlaceSelect = () => {
        const addressObject = autocomplete.getPlace()


        let address1 = '';
        let postcode = '';
        setAddress1('');
        setAddress2('');
        setCity('');
        setState('');
        setCountry('');
        setZipCode('');

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of addressObject.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
                case "subpremise": {
                    setAddress2(component.long_name);
                    break;
                }
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }

                case "route": {
                    address1 += component.short_name;
                    setAddress1(address1)
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    setZipCode(postcode)
                    break;
                }

                case "postal_code_suffix": {
                    postcode = `${postcode}-${component.long_name}`;
                    setZipCode(postcode)
                    break;
                }
                case "locality":
                    setCity(component.long_name);
                    break;
                case "administrative_area_level_1": {
                    setState(component.short_name);
                    break;
                }
                case "country":
                    setCountry(component.short_name);
                    break;
            }
        }


    }


    const searchOnChang = () => {
        const searchString = document.getElementById('search');
        if (event.target.value.length > 5) {
            setAutocomplete(new google.maps.places.Autocomplete(searchString));
            if (autocomplete) {
                autocomplete.addListener("place_changed", handlePlaceSelect)
            }
        }


    }


    useEffect(() => {
        if (!addressData) {
            setDataChanged(false);
            return;
        }
        if (type !== addressData.Type) {
            setDataChanged(true);
            return;
        }
        if (address1 !== addressData.Address1) {
            setDataChanged(true);
            return;
        }
        if (address2 !== addressData.Address2) {
            setDataChanged(true);
            return;
        }
        if (company !== addressData.Company) {
            setDataChanged(true);
            return
        }
        if (city !== addressData.City) {
            setDataChanged(true);
            return
        }
        if (state !== addressData.State) {
            setDataChanged(true);
            return
        }
        if (country !== addressData.Country) {
            setDataChanged(true);
            return
        }
        if (zipCode !== addressData.ZipCode) {
            setDataChanged(true);
            return
        }
        if (phone !== addressData.Phone) {
            setDataChanged(true);
            return
        }
        if (attention !== addressData.Attention) {
            setDataChanged(true);
            return
        }
        setDataChanged(false);
    }, [address1, address2, company, city, state, country, attention, type, zipCode]);

    const onUpdateAddress = async () => {
        setOpen(false);
        setUpdating(true);
        const updatedAddress = {
            Company: company,
            Attention: attention,
            Country: country,
            Address2: address2,
            Address1: address1,
            Type: type,
            State: state,
            City: city,
            ZipCode: zipCode,
            Phone: phone,
            AddressId: addressId,
        }
        try {
            const response = await updateAddress(addressCode, ownerId, updatedAddress)
            if (response.data.status === "200")
            {            if (response.data.message)
            {setSuccessMessage(response.data.message)}
            }
            else if (response.data.status === "400")
            {
                setUpdating(false);
                setIsSuccessVisible(false);
                setIsErrorVisible(true);
                if (response.data.message)
                {setErrorMessage(response.data.message)}
                return;
            }

        } catch (err) {
            setUpdating(false);
            setIsSuccessVisible(false);
            setIsErrorVisible(true);
            console.log(err)
            return;
        }



        setIsSuccessVisible(true);
        setIsErrorVisible(false);
        setAddressData(updatedAddress);
        setUpdating(false);

    }

    const isSubmitDisabled = () => {
        if (updating) {
            return true;
        }
        return !dataChanged;

    };
    const getButtonIcon = () => {
        if (updating) {
            return <CircularProgress size={22}/>;
        }
        return <Home/>;
    };

    const getSelectedItem = ( )=>{
        const item = countries.find((opt)=>{
            if (opt.searchKey.includes(country))
                return opt;
        })

        return item || {};
    };
    return (<Page title="Update Address">
        {isSuccessVisible && (
            <MuiAlert
                onClose={() => setIsSuccessVisible(false)}
                className={classes.alert}
                severity="success"
                elevation={6}
                variant="filled"
            >
                {successMessage}
            </MuiAlert>
        )}
        {isErrorVisible && (
            <MuiAlert
                onClose={() => setIsErrorVisible(false)}
                className={classes.alert}
                severity="error"
                elevation={6}
                variant="filled"
            >
                {errorMessage}
            </MuiAlert>
        )}
        {addressData && (
            <>
                <Grid container spacing={2} alignItems="stretch">
                    <Card className={classes.card}>
                        <CardHeader
                            title="Address Detail"
                            subheader={`Address ID: ${addressId}`}
                        />

                        <CardContent>
                            {/*<Grid>*/}
                            {/*    <TextField*/}
                            {/*        id="search"*/}
                            {/*        className={classes.input}*/}
                            {/*        label="Address Search"*/}
                            {/*        onChange={searchOnChang}*/}
                            {/*        disabled={updating}*/}
                            {/*        variant="standard"*/}
                            {/*        fullWidth*/}
                            {/*        InputProps={{*/}
                            {/*            startAdornment: (*/}
                            {/*                <InputAdornment position="start">*/}
                            {/*                    <SearchIcon />*/}
                            {/*                </InputAdornment>*/}
                            {/*            )}}/>*/}
                            {/*</Grid>*/}
                            <Grid container spacing={1} alignItems="stretch">
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="company"
                                        className={classes.input}
                                        label="Company"
                                        value={company}
                                        onChange={(event) => {
                                            setCompany(event.target.value)
                                        }}
                                        disabled={updating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="attention"
                                        className={classes.input}
                                        label="Attention"
                                        value={attention}
                                        onChange={(event) => {
                                            setAttention(event.target.value)
                                        }}
                                        disabled={updating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="phone"
                                        className={classes.input}
                                        label="Phone"
                                        value={phone}
                                        onChange={(event) => {
                                            setPhone(event.target.value)
                                        }}
                                        disabled={updating}
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <TextField
                                    id="address1"
                                    className={classes.input}
                                    label="Address 1"
                                    value={address1}
                                    onChange={(event) => {
                                        setAddress1(event.target.value);
                                    }}
                                    disabled={updating}
                                    variant="standard"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid>
                                <TextField
                                    id="address2"
                                    className={classes.input}
                                    label="Address 2"
                                    value={address2}
                                    onChange={(event) => {
                                        setAddress2(event.target.value);
                                    }}
                                    disabled={updating}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid>
                            <Grid container spacing={1} alignItems="stretch">
                                <Grid item xs={6}>
                                    <TextField
                                        id="city"
                                        className={classes.input}
                                        label="City"
                                        value={city}
                                        onChange={(event) => {
                                            setCity(event.target.value)
                                        }}
                                        disabled={updating}
                                        variant="standard"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="state"
                                        className={classes.input}
                                        label="State"
                                        value={state}
                                        onChange={(event) => {
                                            setState(event.target.value)
                                        }}
                                        disabled={updating}
                                        variant="standard"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="stretch">
                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="country-select"
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) => {
                                            setCountry(option.code)
                                            return option.label}}
                                        value={getSelectedItem()}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}}  {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.label} ({option.code})
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="country"
                                                className={classes.input}
                                                label="Choose a country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                                disabled={updating}
                                                variant="standard"
                                                required
                                                fullWidth
                                            />
                                        )}
                                    />

                                    {/*<TextField*/}
                                    {/*    id="country"*/}
                                    {/*    className={classes.input}*/}
                                    {/*    label="Country"*/}
                                    {/*    value={country}*/}
                                    {/*    onChange={(event) =>{*/}
                                    {/*        setCountry(event.target.value)*/}
                                    {/*    }}*/}
                                    {/*    disabled={updating}*/}
                                    {/*    variant="standard"*/}
                                    {/*    required*/}
                                    {/*    fullWidth*/}
                                    {/*/>*/}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="zipCode"
                                        className={classes.input}
                                        label="Zip Code"
                                        value={zipCode}
                                        onChange={(event) => {
                                            setZipCode(event.target.value)
                                        }}
                                        disabled={updating}
                                        variant="standard"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid>

                                <FormControl component="fieldset">
                                    <FormLabel id="address-type">Address Type</FormLabel>
                                    <RadioGroup
                                        aria-label="type"
                                        name="group1"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        <FormControlLabel value="R" control={<Radio color="secondary"/>}
                                                          label="Residential"/>
                                        <FormControlLabel value="B" control={<Radio color="primary"/>}
                                                          label="Business"/>
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <br/>
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item className={classes.actionItemsContainer} xs>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={isSubmitDisabled()}
                            startIcon={getButtonIcon()}
                            onClick={handleClickOpen}
                        >
                            Verify Address
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Address Verification"}
                            </DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                {verifyMessage}
                                {!verifyMessage && <LoadingView/>}
                            </DialogContentText>
                            </DialogContent>


                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={getButtonIcon()}
                                    onClick={onUpdateAddress}
                                >
                                    Update Address
                                </Button>
                                <Button variant="outlined" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </>
        )}
        {!addressData && <LoadingView/>}
    </Page>);
}

export default AddressProfile;