import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import Page from '../containers/Page';
import AddressesTable from "../components/AddressesTable";
import {Button, Grid} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  datagrid: {
    flexGrow: 1,
  },
}));


function AddressesList() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const onCreateUser = () => {
    history.push('/address/create');
  };
  const getActionButtons = () => (
      <Grid container direction="row" alignItems="center" justify="flex-end" spacing={1}>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={onCreateUser}>
            Create Address
          </Button>
        </Grid>
      </Grid>
  );
  return (
    <Page title="Address" actionItems={getActionButtons()}>
      <AddressesTable className={classes.datagrid} />
    </Page>
  );
}

export default AddressesList;
