import React from 'react';
import {
  Grid, Popper, Avatar, Grow, ClickAwayListener, Paper, MenuList, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useUser } from '../UserContext';
import AuthGroupWrapper from './AuthGroupWrapper';
import {Typography} from "@mui/material";
import {useAuth} from "../contexts/AuthContext";
import Button from "@material-ui/core/Button";
import {AuthState} from "@aws-amplify/ui-components";

const useStyles = makeStyles((theme) => ({
  root: {},
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: 'pointer',
  },
}));

function UserBadge() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { authState, user: userId, signOut } = useAuth();
  const { user } = useUser();

  console.log(userId);
  console.log(user);


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onProfileEdit = () => {
    history.push('/profile');
    setOpen(false);
  };

  const onManageUsers = () => {
    history.push('/users');
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogin = () => {
    history.push('/login');
  };


  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      history.push('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  // Guest view - Show login button
  if (authState !== AuthState.SignedIn) {
    return (
        <Button
            onClick={handleLogin}
            sx={{
              color: '#708090',
              '&:hover': {
                color: '#4a5a6a',
                backgroundColor: 'transparent'
              },
              textTransform: 'none',
              fontSize: '1rem'
            }}
        >
          Login
        </Button>
    );
  }

  return (
    <div>
      <Grid container wrap="nowrap" spacing={2} className={classes.root}>
        <Grid item>
          <Typography
              ref={anchorRef}
              onClick={handleToggle}
              sx={{
                color: '#708090',
                cursor: 'pointer', // Shows pointer cursor on hover
                '&:hover': {
                  color: '#4a5a6a' // Slightly darker shade on hover
                }
              }}
          >
            {user.name || user.email || 'Guest'}
          </Typography>
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'left-start' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={onProfileEdit}>Edit My Profile</MenuItem>
                  <AuthGroupWrapper requiredGroups={['admin']}>
                    <MenuItem onClick={onManageUsers}>Manage Users</MenuItem>
                  </AuthGroupWrapper>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default UserBadge;
