// assets
import { IconCurrencyDollar,
  IconTruckDelivery,
  IconHelp,
  IconForms,
  IconShip,
  IconHome,
  IconHome2} from '@tabler/icons';

// ============|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=============== //

const jewelBoxMenu = {
  id: 'jewel-box',
  type: 'group',
  children: [
    {
      id: 'homePage',
      title: 'Home Page',
      type: 'item',
      url: '/',
      icon: IconHome,
      breadcrumbs: true,
    },
  ],
};

export default jewelBoxMenu;
