import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import FileName from './DataGrid/FileName';
import UserView from './UserView';
import { getAllAccount } from '../services';
import { getReadableFileSize, getFormattedDate } from '../util';
import LoadingView from './LoadingView';
import List from "../views/List";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function AccountTable() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(null);

  const fetchData = async () => {
    const data = await getAllAccount();
    setTableData(data);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    const interval = setInterval(async () => {
      await fetchData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      name: 'CarrierCode',
      label: 'Account Code',
      options: {
        filter: true,
        filterType: 'textField',
        customFilterListOptions: { render: (v) => `Account Code: ${v}` },
        sort: true,
        // customBodyRenderLite: (dataIndex) => {
        //   const val = tableData[dataIndex];
        //   return (<Link className={classes.root} to={`/address-profile/${val.SK}/${val.UserId}`}>
        //     <div className={classes.nameLabel}>{val.AddressId}</div>
        //   </Link>)
        //       ;
        // },
      },
    },
    {
      name: 'AccountServiceCode',
      label: 'Account Service Code',
      options: {
        filter: true,
        filterType: 'textField',
      },
    },
    {
      name: 'SystemServiceCode',
      label: 'System Service Code',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Pricing',
      label: 'Pricing',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'Signature',
      label: 'Signature',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: function GroupCell(dataIndex) {
          const val = tableData[dataIndex];
          const color = val.Signature ?"primary":"secondary"
          const value = val.Signature ?"T":"F"
          return <Chip label={value} color={color}/>;
        },
      },
    },
    {
      name: 'Multiple',
      label: 'Multiple',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: function GroupCell(dataIndex) {
          const val = tableData[dataIndex];
          const color = val.Multiple ?"primary":"secondary"
          const value = val.Multiple ?"T":"F"
          return <Chip label={value} color={color}/>;
        },
      },
    },
    {
      name: 'Insure',
      label: 'Insure',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: function GroupCell(dataIndex) {
          const val = tableData[dataIndex];
          const color = val.Insure ?"primary":"secondary"
          const value = val.Insure ?"T":"F"
          return <Chip label={value} color={color}/>;
        },
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];


  const options = {
    filterType: 'dropdown',
    selectableRows: 'single',
    fixedSelectColumn: false,
    print: false,
    download: false,
    // onRowsDelete: (rowsDeleted) => {
    //   const itemIdsToDelete = rowsDeleted.data.map((i) => tableData[i.dataIndex]);
    //   return Promise.all(itemIdsToDelete.map((item) => deleteAddress(item.SK, item.UserId)));
    // },
  };

  return (
    <>
      {tableData && (
        <MUIDataTable
          className={classes.root}
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
      {!tableData && <LoadingView />}
    </>
  );
}
