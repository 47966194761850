// assets
import { IconUsers, IconUser, IconUserSearch } from '@tabler/icons';



// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const users = {
  id: 'users',
  title: 'Users',
  type: 'group',
  icon: IconUsers,
  children: [
        {
          id: 'edit-my-profile',
          title: 'Edit My Profile',
          type: 'item',
          icon: IconUser,
          url: '/profile',
          breadcrumbs: false,
        },
        {
          id: 'Manage users',
          title: 'Manage users',
          type: 'item',
          url: '/users',
          icon: IconUserSearch,
          breadcrumbs: false,
          adminOnly: true,
        },

  ],
};

export default users;
