import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0),
  },
  chip: {
    margin: theme.spacing(0.1),
  },
}));

const LineWithTags = ({ data }) => {
  const classes = useStyles();
  const volume = `${data.length} * ${data.width} * ${data.height} IN`;
  const weight = `${data.weight} LBS`

  return (
    <Box className={classes.root}>
      <div className={classes.chipContainer}>
        <Chip label={data.quantity} color="primary" className={classes.chip} />
      </div>
      <div className={classes.chipContainer}>
        <Chip label={volume} color="primary" className={classes.chip} />
      </div>
      <div className={classes.chipContainer}>
        <Chip label={weight} color="primary" className={classes.chip} />
      </div>
    </Box>
  );
};

export default LineWithTags;