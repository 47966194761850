import { Link } from 'react-router-dom';
import { ButtonBase, Typography } from '@mui/material';

const LogoSection = () => (
    <ButtonBase
        disableRipple
        component={Link}
        to={"/"}
        sx={{
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8px'
        }}
    >
        <Typography
            variant="h4"
            sx={{
                fontFamily: "'Playfair Display', serif", // Elegant font
                color: '#708090', // Keeping your original color
                letterSpacing: '2px',
                fontWeight: 600,
                fontSize: '24px',
                textTransform: 'uppercase',
                marginBottom: '-4px'
            }}
        >
            Miss
        </Typography>
        <Typography
            variant="h3"
            sx={{
                fontFamily: "'Playfair Display', serif",
                color: '#708090',
                letterSpacing: '4px',
                fontWeight: 700,
                fontSize: '32px',
                textTransform: 'uppercase'
            }}
        >
            JewelBox
        </Typography>
    </ButtonBase>
);

export default LogoSection;
